import SocialLinks from "./SocialLinks";

function Footer() {
  return (
    <footer className="bg-main-bg relative z-20 py-10 lg:py-14">
      <div className="container-wrapper">
        <div className="mb-10 relative">
          <div className="flex flex-col xl:flex-row space-y-6 xl:space-y-0 items-center justify-between w-full mb-10 xl:mb-0">
            <img
              src="/images/logo.svg"
              className="w-[150px]"
              alt="deelance-logo"
            />
            <div className="flex items-center space-x-[.4em] justify-end text-[76%]">
              <SocialLinks />
            </div>
          </div>
        </div>

        <p className="text-sm text-grey2 font-plus max-w-[46rem] mx-auto text-center">
          {
            "Disclaimer: Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may fluctuate. Profits may be subject to capital gains or other taxes applicable in your jurisdiction."
          }
        </p>

        <div className="h-[1px] w-full bg-white opacity-20 mt-6 mb-10"></div>

        <p className="text-center text-grey2 text-sm lh-1_6">
          {"2023© DeeLance labs LTD | All Rights Reserved"} <br />
          {
            "Ajeltake Road , Reg no: 119495 , Marshall Islands, info@deelance.com"
          }
        </p>
      </div>
    </footer>
  );
}

export default Footer;
