import { useWeb3Modal } from "@web3modal/react";

import { useAccount, useNetwork } from "wagmi";
import Button from "./Button";
import { MdOutlineLogout } from "react-icons/md";
import { BsWallet } from "react-icons/bs";

const ChainIcon = ({ icon }) => {
  return (
    <span className="flex pr-1">
      <img className="w-[1.6em]" src={icon} alt="icon" />
    </span>
  );
};

const ConnectWalletButton = () => {
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();

  const handleConnectWalletClick = () => {
    if (address) {
      open();
      return;
    }

    try {
      open();

      const errorCode = 0;
      window.dataLayer.push({
        event: "workflowStep",
        workflowName: "connectWallet",
        workflowStepNumber: 1,
        workflowStepName: "start",
        workflowCompleteFlag: 0,
        workflowErrorCode: errorCode,
      });
    } catch (error) {
      console.error("Error connecting wallet:", error);
    }
  };

  return (
    <Button
      id="presale-box"
      as="button"
      colorClassName="bg-black"
      // className="text-[64%] lg:text-[70%] xl:text-[86%] cursor-pointer"
      onClick={handleConnectWalletClick}
      variant={address ? 0 : 2}
    >
      {address ? (
        <span className="flex items-center space-x-[.5em] w-fit mx-auto">
          <span>
            {address.slice(0, 3)}...
            {address.slice(address.length - 3, address.length)}
          </span>

          <MdOutlineLogout className="text-[1.4em]" />
        </span>
      ) : (
        <span className="flex items-center space-x-[.5em] w-fit mx-auto">
          <BsWallet className="text-[1.4em]" />
          <span>Connect Wallet</span>
        </span>
      )}
    </Button>
  );
};

export default ConnectWalletButton;
