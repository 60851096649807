import React from "react";
import SocialLinks from "./SocialLinks";
import ConnectWalletButton from "./ConnectWalletButton";
function Navbar() {
  return (
    <div className="h-20 sm:h-24 flex items-center">
      <div className="container-wrapper text-center">
        <div className="flex justify-between">
          <img src="images/logo.svg" alt="" className="h-7 sm:h-9" />

          <div className="flex items-center xl:space-x-8">
            <div className="text-[70%] items-center space-x-[.5em] hidden sm:flex">
              <SocialLinks />
            </div>

            {/* <Web3Button /> */}
            <div className="text-[70%] sm:text-[100%]">
              <ConnectWalletButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
