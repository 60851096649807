import React from "react";
import Wrapper from "./Wrapper";
import { Web3Button, Web3NetworkSwitch } from "@web3modal/react";
import { useState, useEffect } from "react";
import { formatEther, formatUnits } from "viem";
import { useAccount, useContractRead, useContractWrite, useNetwork, useSwitchNetwork} from "wagmi";
import { AIRDROPCONTRACT } from "../Constants/ABI";
import { ContractAddrAir, CHAINAir } from "../Constants/Address";
import { toast } from "react-toastify";
import ConnectWalletButton from "./ConnectWalletButton";

function Airdrop() {
  const { chain, chains } = useNetwork();
  const { address } = useAccount();
  const [bal, setBal] = useState(0);
  const { switchNetwork, isLoading: isSwitchingNetwork } = useSwitchNetwork();
  const isOnCorrectNetwork = address
  ? chain.id === 1
  : false;


  const { data, isError, isLoading} = useContractRead({
  address: ContractAddrAir,
  abi: AIRDROPCONTRACT,
  functionName: "balances",
  args: [address],
  enabled: address ? true : false,
  onSuccess(data) {
  console.log('Success', formatEther(data))
  setBal(formatEther(data));
  },
  onError(error) {
    console.log('Error', error)
  },
  })

/*   const { data: balance } = useContractRead({
    address: ContractAddrAir,
    abi: AIRDROPCONTRACT,
    functionName: "balances",
    args: [address],
    enabled: address ? true : false,
    chainId: CHAINAir,
  });
 */
  const { write: AirDrop, isLoading: isAirdropLoading } = useContractWrite({
    address: ContractAddrAir,
    abi: AIRDROPCONTRACT,
    functionName: "AirDrop",
    chainId: CHAINAir,
    onSuccess: async () => {
      toast(
        "You have successful withdrawn $DLANCE tokens!! Check your wallet!",
        {
          type: "success",
        }
      );
    },
    onError: () => {
      toast("Sorry! You can't take the Airdrop!", { type: "error" });
    },
  });

/*   useEffect(() => {
    console.log("aaa")
    if (address) {
      console.log("bbbb")
      console.log("BALANCES", balance)
      if (balance !== undefined && balance) {
        var giasai = formatEther(balance);
        console.log("GISAIA", giasai);
        if (giasai > 0) {
          setBal(giasai);
        } else {
          setBal(0);
        }
      } else {
        setBal(0);
      }
    }
  }, [balance, isOnCorrectNetwork, address]);   */
  
  return (
    <div className="container-wrapper">
<Wrapper>
  <>
    {
      address ? 
      (
        <>
          <h1 className="text-center text-2xl font-semibold">
            Airdrop Amount to Withdraw
          </h1>
          <h2 className="text-xl text-main-green text-center font-semibold mt-3 mb-8">

          {parseFloat(bal).toFixed(2)} $DLANCE

          </h2>
          {isOnCorrectNetwork ? (
                <button
                    type="submit"
                    className="bg-[#40d98c] rounded-xl border-0 px-11 py-2 text-white capitalize font-medium text-center mx-auto flex"
                    onClick={AirDrop}
                >
                    Withdraw
                </button>
            ) : (
              <button
              type="submit"
              className="bg-[#40d98c] rounded-xl border-0 px-11 py-2 text-white capitalize font-medium text-center mx-auto flex"
              onClick={() => switchNetwork(1)}
          >
              Swith to ETH Network
          </button>
            )}
        </>
      ) : (
      <center> <ConnectWalletButton /> </center>  
      )
    }
  </>
</Wrapper>

    </div>
  );
}

export default Airdrop;
