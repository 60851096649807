import { twMerge } from "tailwind-merge";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function PageLayout({ children, className }) {
  return (
    <div
      className={twMerge("relative z-10 flex flex-col min-h-screen", className)}
    >
      <Navbar />

      <main className="flex-1">{children}</main>

      <Footer />
    </div>
  );
}

export default PageLayout;
