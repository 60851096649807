import React from "react";

function TabBar({ tabs, tab, setTab }) {
  return (
    <div className="w-full border-2 border-main-green-shade-40 rounded-full mb-10 p-1 sm:p-2 grid grid-cols-2 gap-2">
      {tabs?.map((text, i) => (
        <button
          key={i}
          className={`py-1 rounded-full border-2 transition-all duration-200 text-sm lg:text-base ${
            tab === text
              ? "bg-main-green border-main-green text-black font-semibold"
              : "bg-transparent border-main-green-shade-80"
          }`}
          onClick={() => setTab(text)}
        >
          {text}
        </button>
      ))}
    </div>
  );
}

export default TabBar;
