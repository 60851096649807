import React from "react";

const Wrapper = ({ children }) => {
  return (
    <div className="sm:bg-feature-card sm:py-6 lg:py-8 sm:px-8 lg:px-10 rounded-xl sm:border-2 border-main-green-shade-20">
      {children}
    </div>
  );
};

export default Wrapper;
