import React, { useState } from "react";
import ClaimHead from "../components/ClaimHead";
import PageLayout from "../layout/PageLayout";
import TabBar from "../components/TabBar";
import Airdrop from "../components/Airdrop";

const tabs = ["Leaderboard", "Airdrop"];

function Claim() {
  const [tab, setTab] = useState(tabs[0]);

  return (
    <PageLayout>
      <div className="container-wrapper mt-3 flex justify-center">
        <div className="max-w-[30rem] w-full mx-auto">
          <TabBar tab={tab} tabs={tabs} setTab={setTab} />
        </div>
      </div>

      <main className="mb-16">
        {tab === tabs[0] ? <ClaimHead /> : null}

        {tab === tabs[1] ? <Airdrop /> : null}
      </main>
    </PageLayout>
  );
}

export default Claim;
