import React from "react";

const StatCard = ({ title, heading, subtitle }) => {
  return (
    <div className="bg-greyDark border-2 border-main-green-shade-30 py-4 xl:py-6 px-6 rounded-xl">
      <h4 className="text-sm xl:text-base font-semibold opacity-60 mb-3 xl:mb-4 text-white">
        {title}
      </h4>
      <h1 className="font-bold text-base sm:text-lg xl:text-2xl text-main-green">
        {heading}
      </h1>

      {/* {subtitle ? <p className="opacity-80 mt-2">{subtitle}</p> : null} */}
    </div>
  );
};

export default StatCard;
