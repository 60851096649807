import React from "react";
import Timer from "./Timer";
import { Web3Button } from "@web3modal/react";
import { useState, useEffect } from "react";
import { formatEther, formatUnits } from "viem";
import { useAccount, useContractRead, useContractWrite, useNetwork, useSwitchNetwork} from "wagmi";
import { VESTINGCONTRACT } from "../Constants/ABI";
import { ContractAddr, CHAIN } from "../Constants/Address";
import { toast } from "react-toastify";
import Wrapper from "./Wrapper";
import StatCard from "./StatCard";
import ConnectWalletButton from "./ConnectWalletButton";

function ClaimHead() {
  const { address } = useAccount();
  const [futureDate, setFutureDate] = useState(new Date());

  const { chain, chains } = useNetwork();
  const [bal, setBal] = useState(0);
  const { switchNetwork, isLoading: isSwitchingNetwork } = useSwitchNetwork();
  const isOnCorrectNetwork = address
  ? chain.id === 1
  : false;


    //web3 stuff
  const { data: balances } = useContractRead({
    address: ContractAddr,
    abi: VESTINGCONTRACT,
    functionName: "balances",
    args: [address],
    enabled: address ? true : false,
    chainId: CHAIN,
  });

  const { data: lastWithdrawalDate } = useContractRead({
    address: ContractAddr,
    abi: VESTINGCONTRACT,
    functionName: "lastWithdrawalDate",
    args: [address],
    enabled: address ? true : false,
    chainId: CHAIN,
  });

 
  const { write: claim, isLoading: isClaimLoading } = useContractWrite({
    address: ContractAddr,
    abi: VESTINGCONTRACT,
    functionName: "Vesting_Claim",
    chainId: CHAIN,
    onSuccess: async () => {
      toast("You have successful Claim $DLANCE tokens!! Check your wallet!", {
        type: "success",
      });
    },
    onError: () => {
      toast("Sorry! You can't Claim!", { type: "error" });
    },
  });
  
  function setFutureDateFromTimestamp(unixTimestampFromContract) {
    // Convert Unix timestamp to milliseconds
    const timestampInMilliseconds = unixTimestampFromContract * 1000;

    // Initialize a new Date object with the timestamp
    const futureDate = new Date(timestampInMilliseconds);


    return futureDate;
}

useEffect(() => {
  if (address) {
    if (lastWithdrawalDate < 1) { 
      setFutureDate(0);
    } else {
      const calculatedFutureDate = setFutureDateFromTimestamp(parseFloat(lastWithdrawalDate));
      calculatedFutureDate.setDate(calculatedFutureDate.getDate() + 30);
      setFutureDate(calculatedFutureDate);      
    }
  }
}, [address]);



  return (
        <div className="container-wrapper">
      <Wrapper>
       <center> <h1 className="text-lg xl:text-xl font-bold mb-6 text-white">
          Claim your $DLANCE tokens!
        </h1></center>

        <div className="grid lg:grid-cols-4 gap-4 lg:gap-6">
          <StatCard
            title="Still to claim::"
            heading={`${address ? parseFloat(balances).toFixed(2) : 0} $Dlance`}
          />


          <StatCard
            title="Next claim in:"
            heading={address ? <Timer targetDate={futureDate} /> : "0"}
          />

          <StatCard
            title="% for Month"
            heading={`10%`}
          />
        </div>

        <div className="mt-10">
          <div className="flex space-x-4 justify-center">
          {
      address ? 
      (
        <>
          {isOnCorrectNetwork ? (
                <button
                    type="submit"
                    className="bg-[#40d98c] rounded-xl border-0 px-11 py-2 text-white capitalize font-medium text-center mx-auto flex"
                    onClick={claim}
                >
                    Claim
                </button>
            ) : (
              <button
              type="submit"
              className="bg-[#40d98c] rounded-xl border-0 px-11 py-2 text-white capitalize font-medium text-center mx-auto flex"
              onClick={() => switchNetwork(1)}
          >
              Swith to ETH Network
          </button>
            )}
        </>
      ) : (
      <center> <ConnectWalletButton /> </center>  
      )
    }
          </div>
        </div>
      </Wrapper>
    </div> 
  );
}

export default ClaimHead;
