import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Claim from "./pages/Claim";

import { EthereumClient, w3mConnectors } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc, goerli, mainnet } from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PROJECT_ID = "4ff178b5adf37e8779469102693e824b";

// NEW CONFIGURATIONS =======

const chains = [mainnet];

const { publicClient } = configureChains(chains, [
  infuraProvider({ apiKey: "7b50cd907db34540b993f3209ba55488" }),
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId: PROJECT_ID, version: 2, chains }),
  publicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);
const toastStyle = { zIndex: 100000000000000 };

function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <Router>
          <Routes>
            <Route path="/" element={<Claim />} />
          </Routes>
        </Router>
      </WagmiConfig>
      <Web3Modal
        projectId={PROJECT_ID}
        ethereumClient={ethereumClient}
        themeVariables={{ "--w3m-z-index": 1000000000000 }}
      />
      <ToastContainer
        style={toastStyle}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;
