import React, { useState, useEffect } from 'react';

const Timer = ({ targetDate }) => {

    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            let timeDifference = 0;
            if (targetDate == 0) {
            timeDifference = 0;
            } else {
            timeDifference = targetDate - now;
            }

            return {
                days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((timeDifference / (1000 * 60 * 60)) % 24),
            };
        };

        setTimeLeft(calculateTimeLeft());  // initial set

        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [targetDate]);

    return (
        <div>
            <span>{timeLeft.days}d </span>
            <span>{timeLeft.hours}h </span>
        </div>
    );
};

export default Timer;