import { twMerge } from "tailwind-merge";

function Button({
  variant = 0,
  children,
  className,
  colorClassName = "bg-main-bg",
  as = "button",
  ...props
}) {
  const Component = as;
  if (variant === 0) {
    return (
      <Component
        {...props}
        className={twMerge(
          `flex h-[2.4em] items-center justify-center px-[1.25em] rounded-[3.75em] transition-all duration-200 ${colorClassName} group-hover:bg-transparent w-full text-[1em] font-bold font-ibm border-2 border-grey`,
          className
        )}
      >
        {children}
      </Component>
    );
  }

  if (variant === 1) {
    return (
      <Component
        {...props}
        className={twMerge(
          `text-[1em] border-2 border-main-green flex items-center text-center justify-center font-bold text-white bg-transparent transition-all duration-200 hover:bg-main-green h-[2.8em] rounded-[3.75em] hover:text-black focus:border-white outline-none`,
          className
        )}
      >
        {children}
      </Component>
    );
  }

  if (variant === 2) {
    return (
      <Component
        {...props}
        className={`text-[1em] border-2 border-main-green flex text-center justify-center font-bold text-black bg-main-green transition-all duration-200 hover:bg-transparent items-center h-[2.4em] rounded-[3.75em] hover:text-white focus:border-white outline-none px-[1.2em] ${className}`}
      >
        {children}
      </Component>
    );
  }
}

export default Button;
